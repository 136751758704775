
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useEffect } from 'react';
import { NextPage } from 'next';
import { MODAL_HASHES } from '../utils/constants';
import useUrls from '../services/useUrls';
const Login: NextPage = () => {
    const { pushT } = useUrls();
    useEffect(() => {
        pushT(`/${MODAL_HASHES.LOGIN_URL_HASH}`);
    }, []);
    return null;
};
export default Login;

    async function __Next_Translate__getStaticProps__195fa5b0e7e__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195fa5b0e7e__ as getStaticProps }
  